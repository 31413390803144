.navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 4vh;
    flex-wrap: wrap;
    margin-bottom: 3vh;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    margin: 0;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.link {
    color: black;
    margin-right: 2vh;
}

div.link:hover {
    color: red;
}

a {
    text-decoration: none;
}

.name {
    color: black;
}