.about {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 17vh;
}

.about__left {
    display: flex;
    flex: .30;
}

.about__right {
    display: flex;
    flex-direction: column;
    flex: .70;
}

.protfolio__img {
    object-fit: fill;
    width: 60vw;
    height: 70vh;
    max-width: 500px;
    background-color: crimson;
    border-radius: 50%;
}

h3>span {
    color: red;
}

@media screen and (max-width: 900px) {
    .protfolio__img {
        height: 50vh;
        width: 50vw;
    }
}

@media screen and (max-width: 700px) {
    .protfolio__img {
        height: 50vh;
        width: 80vw;
    }
    .about {
        flex-direction: column;
    }
}