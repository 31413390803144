        .resume {
            display: flex;
            justify-content: center;
            align-items: start;
            margin: 0;
            flex-direction: column;
            margin-top: 17vh;
            margin-left: 10vw;
        }
        
        ul {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 0;
        }
        
        li {
            list-style: circle;
        }
        
        .resume__btn {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }